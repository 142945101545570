body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy"), url("./fonts/Gilroy-Light.ttf") format("truetype");
  font-weight: light;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy"), url("./fonts/Gilroy-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy"), url("./fonts/Gilroy-Medium.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy"), url("./fonts/Gilroy-Bold.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Liquido";
  src: local("Liquido"), url("./fonts/liquido-regular.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "HelveticaNowDisplay";
  src: local("HelveticaNowDisplay"),
    url("./fonts/HelveticaNowDisplay-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "HelveticaNowDisplay-ExtraBold";
  src: local("HelveticaNowDisplay-ExtraBold"),
    url("./fonts/HelveticaNowDisplay-ExtraBold.ttf") format("truetype");
  font-weight: 900;
}
